import React, { Fragment } from "react";
import PropTypes from "prop-types";
// import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PageHeader from "../components/PageHeader";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  // tags,
  title,
  helmet
}) => {
  const PostContent = contentComponent || Content;

  return (
    <Fragment>
      <PageHeader title={title} paintColor={"blue"} />
      <section className="section">
        {helmet || ""}
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <p>{description}</p>
              <PostContent content={content} />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        metaimage={post.frontmatter.metaimage}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta property="og:title" content={`${post.frontmatter.title}`} />
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:url"
              content={`https://unveilproductiongroup.com${post.fields.slug}`}
            />
            {post.frontmatter.metaimage ? (
              <meta
                property="og:image"
                content={`https://unveilproductiongroup.com${
                  post.frontmatter.metaimage.childImageSharp.fluid.src
                }`}
              />
            ) : null}
          </Helmet>
        }
        // tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
      {console.log(data)}
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        subTitle
        metaimage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 400, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
      }
    }
  }
`;
